import {createSlice} from '@reduxjs/toolkit';
import {
  HOURS_PER_SHIFT,
  HOURS_PER_SHIFT_COST,
  STEPS_NUMBERS,
} from 'src/shared/constants/AppConst';
import {BookingMarkupReference} from 'src/shared/constants/AppEnums';
import {PaymentListDto} from 'src/shared/services/booking-payment/dto/payment-list-dto';
import {BookingShiftsDto} from 'src/shared/services/booking/dto/booking-shifts-dto';
import {BookingDetailsDto} from 'src/shared/services/booking/dto/details-booking-dto';

interface BookingInitialState {
  bookingDetails: BookingDetailsDto | undefined;
  isBookingDetailsLoading: boolean;
  currentTabInBookingDetails: number;
  isFoundBooking: boolean;
  isFetchShiftsLoading: boolean;
  isFetchBookingShifts: boolean;
  shiftsAllDetails: Array<BookingShiftsDto>;
  bookingShifts: Array<BookingShiftsDto>;
  oldBookingShifts: Array<BookingShiftsDto>;
  selectShiftsAll: boolean;
  paymentList?: PaymentListDto;
  refreshPaymentList?: boolean;
  plannedMarkup: number;
  manuallyMarkup?: number;
  markupReference: number;
  hoursPerShiftCost: number;
  hoursPerShift: number;
  numberOfShifts: number;
  spanDays: number;
  totalClientCost?: number;
  markupType?: number;
  isShiftsRequired?: boolean;
  isCaregiverAssignmentRequired?: boolean;
  isPaymentsRequired?: boolean;
  disabledBack?: boolean;
  filterStatusListInShifts?: Array<number>;
  isBookingDetailsPage?: boolean;
  newNotificationForBooking?: any;
  updateBookingShiftFees?: BookingShiftsDto;
}

const initialState: BookingInitialState = {
  bookingDetails: undefined,
  isBookingDetailsLoading: false,
  currentTabInBookingDetails: STEPS_NUMBERS.One,
  isFoundBooking: false,
  isFetchShiftsLoading: false,
  isFetchBookingShifts: false,
  shiftsAllDetails: [],
  bookingShifts: [],
  oldBookingShifts: [],
  selectShiftsAll: false,
  paymentList: undefined,
  refreshPaymentList: false,
  plannedMarkup: 0,
  manuallyMarkup: undefined,
  markupReference: BookingMarkupReference.planned,
  hoursPerShiftCost: HOURS_PER_SHIFT_COST,
  hoursPerShift: HOURS_PER_SHIFT,
  numberOfShifts: 0,
  spanDays: 0,
  totalClientCost: 0,
  markupType: undefined,
  isShiftsRequired: false,
  isCaregiverAssignmentRequired: false,
  isPaymentsRequired: false,
  disabledBack: false,
  filterStatusListInShifts: [],
  isBookingDetailsPage: false,
  newNotificationForBooking: undefined,
  updateBookingShiftFees: undefined,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialState,
  reducers: {
    setBookingDetails: (state, action) => {
      state.bookingDetails = action.payload;
    },
    setIsBookingDetailsLoading: (state, action) => {
      state.isBookingDetailsLoading = action.payload;
    },
    setCurrentTabInBookingDetails: (state, action) => {
      state.currentTabInBookingDetails = action.payload;
    },
    setIsFoundBooking: (state, action) => {
      state.isFoundBooking = action.payload;
    },
    setIsFetchShiftsLoading: (state, action) => {
      state.isFetchShiftsLoading = action.payload;
    },
    setIsFetchBookingShifts: (state, action) => {
      state.isFetchBookingShifts = action.payload;
    },
    setShiftsAllDetails: (state, action) => {
      state.shiftsAllDetails = action.payload;
    },
    setBookingShifts: (state, action) => {
      state.bookingShifts = action.payload;
    },
    setOldBookingShifts: (state, action) => {
      state.oldBookingShifts = action.payload;
    },
    setSelectShiftsAll: (state, action) => {
      state.selectShiftsAll = action.payload;
    },
    setPaymentList: (state, action) => {
      state.paymentList = action.payload;
    },
    setRefreshPaymentList: (state, action) => {
      state.refreshPaymentList = action.payload;
    },
    setPlannedMarkup: (state, action) => {
      state.plannedMarkup = action.payload;
    },
    setManuallyMarkup: (state, action) => {
      state.manuallyMarkup = action.payload;
    },
    setMarkupReference: (state, action) => {
      state.markupReference = action.payload;
    },
    setHoursPerShiftCost: (state, action) => {
      state.hoursPerShiftCost = action.payload;
    },
    setHoursPerShift: (state, action) => {
      state.hoursPerShift = action.payload;
    },
    setNumberOfShifts: (state, action) => {
      state.numberOfShifts = action.payload;
    },
    setSpanDays: (state, action) => {
      state.spanDays = action.payload;
    },
    setTotalClientCost: (state, action) => {
      state.totalClientCost = action.payload;
    },
    setMarkupType: (state, action) => {
      state.markupType = action.payload;
    },
    setIsShiftsRequired: (state, action) => {
      state.isShiftsRequired = action.payload;
    },
    setIsCaregiverAssignmentRequired: (state, action) => {
      state.isCaregiverAssignmentRequired = action.payload;
    },
    setIsPaymentRequired: (state, action) => {
      state.isPaymentsRequired = action.payload;
    },
    setDisabledBack: (state, action) => {
      state.disabledBack = action.payload;
    },
    setFilterStatusListInShifts: (state, action) => {
      state.filterStatusListInShifts = action.payload;
    },
    setIsBookingDetailsPage: (state, action) => {
      state.isBookingDetailsPage = action.payload;
    },
    setNewNotificationForBooking: (state, action) => {
      state.newNotificationForBooking = action.payload;
    },
    setUpdateBookingShiftFees: (state, action) => {
      state.updateBookingShiftFees = action.payload;
    },
  },
});

export const {
  setBookingDetails,
  setIsBookingDetailsLoading,
  setCurrentTabInBookingDetails,
  setIsFoundBooking,
  setIsFetchShiftsLoading,
  setIsFetchBookingShifts,
  setShiftsAllDetails,
  setBookingShifts,
  setOldBookingShifts,
  setSelectShiftsAll,
  setPaymentList,
  setRefreshPaymentList,
  setPlannedMarkup,
  setManuallyMarkup,
  setMarkupReference,
  setHoursPerShiftCost,
  setHoursPerShift,
  setNumberOfShifts,
  setSpanDays,
  setTotalClientCost,
  setMarkupType,
  setIsShiftsRequired,
  setIsCaregiverAssignmentRequired,
  setIsPaymentRequired,
  setDisabledBack,
  setFilterStatusListInShifts,
  setIsBookingDetailsPage,
  setNewNotificationForBooking,
  setUpdateBookingShiftFees,
} = bookingSlice.actions;

export default bookingSlice.reducer;
