import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';

interface DailyShiftsInitialState {
  searchByName: string;
  fromAndToFilter?: {
    to: string;
    from: string;
  };
}

const initialState: DailyShiftsInitialState = {
  searchByName: '',
  fromAndToFilter: {
    from: moment(new Date()?.setHours(0, 0, 0, 0)).toISOString(),
    to: moment(new Date()?.setHours(23, 59, 59, 59)).toISOString(),
  },
};

const dailyShiftsSlice = createSlice({
  name: 'dailyShifts',
  initialState: initialState,
  reducers: {
    setSearchByName: (state, action) => {
      state.searchByName = action.payload;
    },
    setFromAndToFilter: (state, action) => {
      state.fromAndToFilter = action.payload;
    },
  },
});

export const {setSearchByName, setFromAndToFilter} = dailyShiftsSlice.actions;

export default dailyShiftsSlice.reducer;
