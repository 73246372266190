import {createSlice} from '@reduxjs/toolkit';
import {
  HOURS_PER_SHIFT,
  HOURS_PER_SHIFT_COST,
  STEPS_NUMBERS,
} from 'src/shared/constants/AppConst';
import {BookingMarkupReference} from 'src/shared/constants/AppEnums';
import {BookingShiftsDto} from 'src/shared/services/booking/dto/booking-shifts-dto';
import {CaseDetailsDto} from 'src/shared/services/cases/dto/details-case-dto';

interface CasesInitialState {
  caseDetails: CaseDetailsDto | undefined;
  currentTabInCaseDetails: number;
  currentTabInSchedule: number;
  newStartDate: any;
  refreshCaregiverResponses: boolean;
  totalBookingCost?: number;
  plannedMarkupInCase?: number;
  manuallyMarkupInCase?: number;
  markupReferenceInCase?: number;
  spanDays?: number;
  markupType?: number;
  shiftsDetails: Array<BookingShiftsDto>;
  displayShifts: Boolean;
  hoursPerShiftCost: number;
  hoursPerShift: number;
  openQuotationModal: boolean;
}

const initialState: CasesInitialState = {
  caseDetails: undefined,
  currentTabInCaseDetails: STEPS_NUMBERS.One,
  currentTabInSchedule: STEPS_NUMBERS.One,
  newStartDate: undefined,
  refreshCaregiverResponses: false,
  totalBookingCost: 0,
  plannedMarkupInCase: 0,
  manuallyMarkupInCase: undefined,
  markupReferenceInCase: BookingMarkupReference.planned,
  spanDays: 0,
  markupType: 0,
  shiftsDetails: [],
  displayShifts: false,
  hoursPerShiftCost: HOURS_PER_SHIFT_COST,
  hoursPerShift: HOURS_PER_SHIFT,
  openQuotationModal: false,
};

const casesSlice = createSlice({
  name: 'cases',
  initialState: initialState,
  reducers: {
    setCaseDetails: (state, action) => {
      state.caseDetails = action.payload;
    },
    setCurrentTabInCaseDetails: (state, action) => {
      state.currentTabInCaseDetails = action.payload;
    },
    setCurrentTabInSchedule: (state, action) => {
      state.currentTabInSchedule = action.payload;
    },
    setNewStartDate: (state, action) => {
      state.newStartDate = action.payload;
    },
    setRefreshCaregiverResponses: (state, action) => {
      state.refreshCaregiverResponses = action.payload;
    },
    setTotalBookingCost: (state, action) => {
      state.totalBookingCost = action.payload;
    },
    setPlannedMarkupInCase: (state, action) => {
      state.plannedMarkupInCase = action.payload;
    },
    setManuallyMarkupInCase: (state, action) => {
      state.manuallyMarkupInCase = action.payload;
    },
    setMarkupReferenceInCase: (state, action) => {
      state.markupReferenceInCase = action.payload;
    },
    setSpanDaysInCase: (state, action) => {
      state.spanDays = action.payload;
    },
    setMarkupTypeInCase: (state, action) => {
      state.markupType = action.payload;
    },
    setShiftsDetailsInCase: (state, action) => {
      state.shiftsDetails = action.payload;
    },
    setDisplayShifts: (state, action) => {
      state.displayShifts = action.payload;
    },
    setHoursPerShiftCostInCase: (state, action) => {
      state.hoursPerShiftCost = action.payload;
    },
    setHoursPerShiftInCase: (state, action) => {
      state.hoursPerShift = action.payload;
    },
    setOpenQuotationModal: (state, action) => {
      state.openQuotationModal = action.payload;
    },
  },
});

export const {
  setCaseDetails,
  setCurrentTabInCaseDetails,
  setCurrentTabInSchedule,
  setNewStartDate,
  setRefreshCaregiverResponses,
  setTotalBookingCost,
  setPlannedMarkupInCase,
  setManuallyMarkupInCase,
  setMarkupReferenceInCase,
  setSpanDaysInCase,
  setMarkupTypeInCase,
  setShiftsDetailsInCase,
  setDisplayShifts,
  setHoursPerShiftCostInCase,
  setHoursPerShiftInCase,
  setOpenQuotationModal,
} = casesSlice.actions;

export default casesSlice.reducer;
