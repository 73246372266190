import {createSlice} from '@reduxjs/toolkit';
import {PricingSettingsAllDetailsDto} from 'src/shared/services/pricing-settings/dto/pricing-settings-all-details-dto';

interface PricingSettingsInitialState {
  pricingSettingsAllList?: PricingSettingsAllDetailsDto;
  isPricingSettingsLoading: Boolean;
  dailyCost: number;
}

const initialState: PricingSettingsInitialState = {
  pricingSettingsAllList: undefined,
  isPricingSettingsLoading: false,
  dailyCost: 0,
};

const pricingSettingsSlice = createSlice({
  name: 'pricingSettings',
  initialState: initialState,
  reducers: {
    setPricingSettingsAllList: (state, action) => {
      state.pricingSettingsAllList = action.payload;
    },
    setIsPricingSettingsLoading: (state, action) => {
      state.isPricingSettingsLoading = action.payload;
    },
    setDailyCost: (state, action) => {
      state.isPricingSettingsLoading = action.payload;
    },
  },
});

export const {
  setPricingSettingsAllList,
  setIsPricingSettingsLoading,
  setDailyCost,
} = pricingSettingsSlice.actions;

export default pricingSettingsSlice.reducer;
